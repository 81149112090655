<template>
    <div class="form-group">
        <label :for="id">
            <slot />
        </label>
        <textarea :id="id" class="form-control" :rows="rows" :value="value" :placeholder="placeholder"
            :disabled="disabled"></textarea>
    </div>
</template>

<script>
export default {
    name: "ArgonTextarea",
    props: {
        id: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        rows: {
            type: Number,
            default: 5,
        },
        value: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
};
</script>
