import store from "../store";

export default async function auth({ next }) {
    if (!store.getters["auth/isAuthenticated"]) {
        return next({ name: "Login" });
    }
    try {
        await store.dispatch("profile/me");
    } catch (error) {
        await store.dispatch("auth/removeToken");
        return next({ name: "Login" });
    }

    next();
}
