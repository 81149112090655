<template>
    <div id="apiProcess" class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card card-body mt-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <h5 class="mb-0">Ai Process</h5>
                        <span @click="toggleSettings" class="fa fa-cog cursor-pointer align-middle" />
                    </div>
                    <div v-show="showConfiguration" class="row">
                        <div class="col-6">
                            <div class="mt-4">
                                <div class="table-responsive">
                                    <label class="form-label">Ai Version</label>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    MODEL</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    DESCRIPTION</th>
                                                <th
                                                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    CONTEXT WINDOW</th>
                                                <th
                                                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    TRAINING DATA
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><argon-radio id="gpt-4-1106-preview" v-model="aiProcess.api_version"
                                                        name="gpt-4-1106-preview" value="gpt-4-1106-preview"
                                                        :checked="aiProcess.api_version == 'gpt-4-1106-preview' ? true : false">gpt-4-1106-preview</argon-radio>
                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">The latest GPT-4 model with improved
                                                        instruction
                                                        following, JSON
                                                        mode,
                                                        reproducible
                                                        outputs, parallel function calling, and more. Returns a maximum
                                                        of
                                                        4,096
                                                        output tokens.
                                                        This preview model is not yet suited for production traffic.</p>
                                                </td>
                                                <td class="text-sm">128,000 tokens</td>
                                                <td class="text-sm">Up to Apr 2023</td>
                                            </tr>
                                            <tr>
                                                <td><argon-radio id="gpt-4" v-model="aiProcess.api_version" name="gpt-4"
                                                        value="gpt-4"
                                                        :checked="aiProcess.api_version == 'gpt-4' ? true : false">gpt-4</argon-radio>
                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">Snapshot of gpt-4 from June 13th 2023
                                                        with improved function calling support.</p>
                                                </td>
                                                <td class="text-sm">8,192 tokens</td>
                                                <td class="text-sm">Up to Sep 2021</td>
                                            </tr>
                                            <tr>
                                                <td><argon-radio id="gpt-4-32k" v-model="aiProcess.api_version"
                                                        name="gpt-4-32k" value="gpt-4-32k"
                                                        :checked="aiProcess.api_version == 'gpt-4-32k' ? true : false">gpt-4-32k</argon-radio>
                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">Snapshot of gpt-4-32k from June 13th
                                                        2023 with improved function calling support.</p>
                                                </td>
                                                <td class="text-sm">32,768 tokens</td>
                                                <td class="text-sm">Up to Sep 2021</td>
                                            </tr>
                                            <tr>
                                                <td> <argon-radio id="gpt-3.5-turbo-1106" v-model="aiProcess.api_version"
                                                        name="gpt-3.5-turbo-1106" value="gpt-3.5-turbo-1106"
                                                        :checked="aiProcess.api_version == 'gpt-3.5-turbo-1106' ? true : false">gpt-3.5-turbo-1106</argon-radio>
                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">The latest GPT-3.5 Turbo model with
                                                        improved instruction following, JSON mode, reproducible outputs,
                                                        parallel function calling, and more. Returns a maximum of 4,096
                                                        output tokens.</p>
                                                </td>
                                                <td class="text-sm">16,385 tokens</td>
                                                <td class="text-sm">Up to Sep 2021</td>
                                            </tr>
                                            <tr>
                                                <td><argon-radio id="gpt-3.5-turbo" v-model="aiProcess.api_version"
                                                        name="gpt-3.5-turbo" value="gpt-3.5-turbo"
                                                        :checked="aiProcess.api_version == 'gpt-3.5-turbo' ? true : false">gpt-3.5-turbo</argon-radio>
                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">Snapshot of gpt-3.5-turbo from June 13th
                                                        2023.</p>
                                                </td>
                                                <td class="text-sm">4,096 tokens</td>
                                                <td class="text-sm">Up to Sep 2021</td>
                                            </tr>
                                            <tr>
                                                <td> <argon-radio id="gpt-3.5-turbo-16k" v-model="aiProcess.api_version"
                                                        name="gpt-3.5-turbo-16k" value="gpt-3.5-turbo-16k"
                                                        :checked="aiProcess.api_version == 'gpt-3.5-turbo-16k' ? true : false">gpt-3.5-turbo-16k</argon-radio>

                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">Snapshot of gpt-3.5-16k-turbo from June
                                                        13th 2023.</p>
                                                </td>
                                                <td class="text-sm">16,385 tokens</td>
                                                <td class="text-sm">Up to Sep 2021</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mt-4">
                                <label class="form-label">API KEY</label>
                                <argon-input v-model="aiProcess.api_key" type="text" placeholder="API KEY"
                                    :value="aiProcess.api_key" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <form role="form" @submit.prevent="handleCreateProcess()">
            <div class="row">
                <div class="col-12">
                    <div class="card card-body mt-4">
                        <label class="form-label">Ai Process Name</label>
                        <argon-input id="aiProcessTitle" v-model="aiProcess.name" type="text" placeholder="Ai Process Name"
                            :value="aiProcess.name" required />
                        <argon-textarea id="aiProcessText" :value="aiProcess.text"
                            @input="aiProcess.text = $event.target.value" :rows="10" placeholder="Text Template">Text
                            Template ({{ aiProcess.text?.length }})</argon-textarea>
                    </div>
                </div>
                <div v-for="prompt in aiProcess.prompts" :key="prompt.index" class="col-md-4">
                    <div class="card card-body mt-4">
                        <label class="form-label">Prompt Title</label>
                        <!-- <input type="text" placeholder="Prompt Title"  v-model="prompt.title" @blur="alert('test')"> -->
                        <argon-input type="text" placeholder="Prompt Title" :value="prompt.name" v-model="prompt.name"
                            :disabled="prompt.disabled" @blur="handleBlur(prompt)" />
                        <argon-textarea :id="`promptText${prompt.index}`" placeholder="Prompt Text" :value="prompt.text"
                            @input="prompt.text = $event.target.value">
                            Prompt Text
                            <div v-if="prompt.wildcards?.length > 0">available wildcards: <span
                                    @click="copyText($event, prompt.index)" v-for="wildcard in prompt.wildcards"
                                    :key="wildcard" style="color: #31cc8a;margin-right: .5em;cursor: pointer;">(({{ wildcard
                                    }}))</span>
                            </div>
                        </argon-textarea>
                        <div v-show="prompt.result">
                            <hr>
                            <h5>Prompt {{ prompt.name }} Result</h5>
                            <p>{{ prompt.result }}</p>
                        </div>
                        <div>
                            <argon-button type="button" v-if="prompt.index > 0"
                                style="position: absolute; top: -20px;right: -10px;padding: 0.8rem 1rem;"
                                class="mt-2 d-flex align-items-center" color="danger" size="md" variant="gradient"
                                @click="removePrompt(prompt.index)"><i class="fas fa-trash fa-lg"></i></argon-button>
                        </div>
                    </div>
                </div>

                <div id="newPropmtPlaceHolder" ref="newPropmtPlaceHolder" class="col-md-4">
                    <place-holder-card class="mt-4 mb-4 h-auto" @click="addPrompt"
                        :title="{ variant: 'h4', text: 'New Prompt' }" />
                </div>
                <div class="loader" v-if="isLoading">
                    <p>Your request is being processed, please don't close this window</p>
                    <br>
                    <div class="spinner-border text-default" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
            </div>

            <div v-show="isSaveASNameVisible" class="col-12 mt-4">
                <label class="form-label">New Ai Process Name</label>
                <argon-input id="NewAiProcessTitle" ref="NewAiProcessTitle" v-model="aiProcess.name" type="text"
                    placeholder="New Ai Process Name" :value="aiProcess.name" required />
            </div>
            <div class="row mt-4">
                <div v-show="!isSaveASNameVisible" class="col-2 offset-3">
                    <argon-button class="mt-4 mx-auto d-block" color="success" size="lg" variant="gradient"
                        :disabled="isLoading">Run</argon-button>
                </div>
                <div :class="!isSaveASNameVisible ? 'col-2' : 'col-2 offset-5'">
                    <argon-button @click="handleSaveProcess" class="mt-4 mx-auto d-block" color="info" size="lg"
                        variant="gradient" type="button" :disabled="isLoading">Save</argon-button>
                </div>
                <div class="col-2">
                    <argon-button @click="toggleIsSaveASNameVisible" class="mt-4 mx-auto d-block" :color="saveAsColor"
                        size="lg" variant="gradient" type="button" :disabled="isLoading">{{ isSaveASNameVisible ? "Cancel" :
                            "Save as" }}</argon-button>
                </div>

            </div>
        </form>
    </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonRadio from '@/components/ArgonRadio.vue';
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import PlaceHolderCard from "@/examples/Cards/PlaceholderCard.vue";

import showSwal from "@/mixins/showSwal.js"

export default {

    name: "AiProcessPage",

    props: [],

    components: {
        ArgonInput,
        ArgonTextarea,
        ArgonButton,
        ArgonRadio,
        PlaceHolderCard
    },

    mixins: [showSwal],

    data() {
        return {
            showConfiguration: true,
            isLoading: false,
            isSaveASNameVisible: false,
            saveAsColor: "info",
            aiProcess: {
                type: "aiProcesses",
                api_key: "",
                api_version: "",
                name: "",
                text: "",
                prompts: [{ index: 0, name: "", text: "", result: "", wildcards: ["text template"], disabled: false }],
            },
            aiSetting: {
                type: "aiProcessSettings",
                api_key: "",
                api_version: "",
            }
        }
    },

    computed: {

    },

    async mounted() {
        await this.$store.dispatch("aiprocess/getAiProcess", this.$route.params.id);
        this.aiProcess = await this.$store.getters["aiprocess/oneAiProcess"];

        console.log(this.aiProcess);
        this.aiProcess.prompts.forEach(prompt => {
            prompt.wildcards = JSON.parse(prompt.wildcards);
        });

        delete this.aiProcess.relationshipNames;

    },

    async updated() {
    },

    methods: {
        addPrompt() {
            if (this.aiProcess.prompts[this.aiProcess.prompts?.length - 1].name === "" || this.aiProcess.prompts[this.aiProcess.prompts?.length - 1].text === "") {
                this.showSwal({
                    type: "error",
                    message: "Please fill out the last prompt before adding a new one.",
                });
                return;
            }

            // this.aiProcess.prompts[this.aiProcess.prompts?.length - 1].disabled = true;
            const wildcards = [...this.aiProcess.prompts[this.aiProcess.prompts?.length - 1].wildcards];
            wildcards.push(this.aiProcess.prompts[this.aiProcess.prompts?.length - 1].name);
            this.aiProcess.prompts.push({ index: this.aiProcess.prompts?.length, name: "", text: "", wildcards: wildcards });
        },
        removePrompt(index) {
            this.aiProcess.prompts.splice(index, 1);
            this.reinitPrompts();
            // this.aiProcess.prompts[this.aiProcess.prompts?.length - 1].disabled = false;
        },
        copyText(e, index) {
            const copyText = e.target.innerText;
            navigator.clipboard.writeText(copyText);
            this.aiProcess.prompts[index].text += " " + copyText + " ";
        },

        toggleSettings() {
            this.showConfiguration = !this.showConfiguration;
        },
        async toggleIsSaveASNameVisible() {
            this.isSaveASNameVisible = !this.isSaveASNameVisible;

            if (this.isSaveASNameVisible) {
                this.saveAsColor = "danger";
                setTimeout(() => {
                    const input = this.$el.querySelector("#NewAiProcessTitle");
                    input.focus();
                }, 200);
            } else {
                this.saveAsColor = "info";

            }
        },
        reinitPrompts() {
            this.aiProcess.prompts.forEach((prompt, index) => {

                if (index === 0) {
                    return;
                }
                prompt.index = index;

                prompt.wildcards = [...this.aiProcess.prompts[index - 1]?.wildcards];
                this.aiProcess.prompts[prompt.index].wildcards.push(this.aiProcess.prompts[prompt.index - 1].name);

            });
        },
        handleBlur(currentPrompt) {
            if (this.aiProcess.prompts?.length > currentPrompt.index) {
                this.aiProcess.prompts.forEach(prompt => {

                    if (prompt.index === 0 || prompt.index <= currentPrompt.index) {
                        return;
                    }

                    if (prompt.index === currentPrompt.index + 1) {
                        this.aiProcess.prompts[prompt.index].wildcards = [...this.aiProcess.prompts[prompt.index - 1].wildcards];
                        this.aiProcess.prompts[prompt.index].wildcards.push(currentPrompt.name);
                        return;
                    }

                    this.aiProcess.prompts[prompt.index].wildcards = [...this.aiProcess.prompts[prompt.index - 1].wildcards];
                    this.aiProcess.prompts[prompt.index].wildcards.push(this.aiProcess.prompts[prompt.index - 1].name);
                });
            }
        },
        async handleCreateProcess() {
            try {
                this.isLoading = true;
                // this.resetApiValidation();
                await this.$store.dispatch("aiprocess/addAiProcess", this.aiProcess);
                console.log(this.aiProcess);

                this.showSwal({
                    type: "success",
                    message: "Process finished successfully!",
                });

                this.aiProcess = await this.$store.getters["aiprocess/oneAiProcess"];

                this.aiProcess.prompts.forEach(prompt => {
                    prompt.wildcards = JSON.parse(prompt.wildcards);
                });
                delete this.aiProcess.relationshipNames;
                this.isLoading = false;

            } catch (error) {
                this.isLoading = false;
                console.log(this.aiProcess);

                // this.setApiValidation(error.response.data.errors);
                this.showSwal({
                    type: "error",
                    message: "Oops, something went wrong!",
                });
            }
        },
        async handleSaveProcess() {
            try {
                this.isLoading = true;
                // this.resetApiValidation();
                await this.$store.dispatch("aiprocess/updateAiProcess", this.aiProcess);
                console.log(this.aiProcess);

                this.showSwal({
                    type: "success",
                    message: "Process finished successfully!",
                });

                this.aiProcess = await this.$store.getters["aiprocess/oneAiProcess"];

                this.aiProcess.prompts.forEach(prompt => {
                    prompt.wildcards = this.isValidJson(prompt.wildcards) ? JSON.parse(prompt.wildcards) : prompt.wildcards;
                });
                delete this.aiProcess.relationshipNames;
                this.isLoading = false;
                this.isSaveASNameVisible = false;
            } catch (error) {
                this.isLoading = false;
                console.log(error);
                // this.setApiValidation(error.response.data.errors);
                this.showSwal({
                    type: "error",
                    message: error,
                });
            }
        },
        async handleSaveAsProcess() {

        },
        isValidJson(json) {
            try {
                JSON.parse(json);
                return true;
            } catch (e) {
                return false;
            }
        },
    },
};
</script>
