<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">Open Ai Processes List</h5>
                </div>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                  <div class="my-auto ms-auto">
                    <router-link :to="{ name: 'AI Process' }"
                      class="mb-0 btn bg-gradient-success btn-sm">
                        <i class="fa fa-plus me-1"/> Ai Process
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="px-0 pb-0 card-body">
              <div class="dataTable-search search-block">
                <input v-model="search" class="dataTable-input search-input-table" placeholder="Search..." type="text">
              </div>
              <div class="table-responsive">
                <table id="tag-list" ref="aiProcessesList" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th title="name">Name</th>

                      <th title="created_at">Created At</th>
                      <th data-sortable="false">Action</th>
                    </tr>
                  </thead>
                  <tbody class="text-sm">
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Name</th>

                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div v-if="pagination" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="ms-3">
                <p>
                  Showing {{ pagination?.total ? metaPage?.from : 0 }} to {{ metaPage?.to }} of
                  {{ pagination?.total }} entries
                </p>
              </div>
              <BasePagination v-model="pagination.currentPage"
                class="pagination-success pagination-md me-3" :value="pagination.currentPage"
                :per-page="pagination.perPage" :total="pagination.total" @click="getDataFromPage($event)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { DataTable } from "simple-datatables";
import store from "../../store";
import BasePagination from "../../components/BasePagination.vue";
import eventTable from "../../mixins/eventTable.js";
import _ from "lodash";

var sortDirection = "created_at";
var getCurrentPage = 1;
var searchQuery = '';

const getAiProcessesList = _.debounce(async function (params) {
  await store.dispatch("aiprocess/aiProcessesList", {
    ...(params.sort ? { sort: params.sort } : {}),
    filter: {
      ...(params.query ? { name: params.query } : {}),
    },
    page: {
      number: params.nr,
      size: params.perpage,
    },
  });
}, 300);

export default {
  name: "ListAiProcessesPage",
  components: {
    BasePagination
  },
  mixins: [eventTable],
  data() {
    return {
      dataAiProcesses: [],
      pagination: {},
      tableAiProcesses: null,
      keys: null,
      search: ''
    }
  },
  computed: {
    aiProcessesList() {
      return this.$store.getters["aiprocess/aiProcessesList"]?.data;
    },
    metaPage() {
      return this.$store.getters["aiprocess/aiProcessesList"]?.meta;
    }
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    aiProcessesList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    search: {
      handler: "reactiveSearch"
    }
  },
  async mounted() {
    if (this.$refs.aiProcessesList) {

      this.tableAiProcesses = new DataTable(this.$refs.aiProcessesList, {
        searchable: false,
        fixedHeight: false,
        perPage: 5,
      });

      document.querySelector(".dataTable-bottom").remove()

      this.tableAiProcesses.label = null;
      this.tableAiProcesses.setMessage("Loading");

      await getAiProcessesList({
        sort: sortDirection,
        query: '',
        nr: getCurrentPage,
        perpage: this.tableAiProcesses.options.perPage
      });

      this.tableAiProcesses.on('datatable.perpage', async function (perpage) {
        this.setMessage('Loading');
        await getAiProcessesList({
          sort: sortDirection,
          query: searchQuery,
          nr: getCurrentPage = 1,
          perpage: perpage
        });
      });

      this.tableAiProcesses.on('datatable.sort', async function (column, direction) {
        column = this.headings[column].title;
        direction = direction == "asc" ? "" : "-";
        await getAiProcessesList({
          sort: sortDirection = direction + column,
          query: searchQuery,
          nr: getCurrentPage,
          perpage: this.options.perPage
        });
      });
    };
  },
  beforeUnmount() {
    sortDirection = "created_at";
    searchQuery = '';
    getCurrentPage = 1;
  },
  methods: {
    async getDataFromPage(page) {
      await getAiProcessesList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        perpage: this.tableAiProcesses.options.perPage
      });
    },

    async reactiveSearch() {
      searchQuery = this.search;
      await getAiProcessesList({
        sort: sortDirection,
        query: this.search,
        nr: getCurrentPage = 1,
        perpage: this.tableAiProcesses.options.perPage
      });
    },

    async reactivePagination() {
      this.pagination = await this.metaPage;

      if(!this.pagination)
        return;

      this.keys = Object.keys(this.pagination);

      this.pagination = {
        currentPage: this.pagination[this.keys[0]],
        perPage: this.pagination[this.keys[1]],
        total: this.pagination[this.keys[4]]
      }
      getCurrentPage = this.pagination.currentPage;
      return this.pagination;
    },

    async reactiveTable() {
      this.dataAiProcesses = [];
      if (this.aiProcessesList.length > 0) {
        this.aiProcessesList.forEach(row => {
          this.dataAiProcesses.push(
            [
              `<h6 class="my-auto">${row.name}</h6>`,

              row.created_at,
              this.actionShowButton(row.id, "Show AI Process") + this.actionDeleteButton(row.id, "Delete AI Process")
            ]
          )
        });

        this.tableAiProcesses.data = [];
        this.tableAiProcesses.refresh();
        this.tableAiProcesses.insert({ data: this.dataAiProcesses });
        this.removeEvent();
        this.eventToCall({
          table: this.tableAiProcesses,
          redirectPath: "Show AI Process",
          deletePath: "aiprocess/deleteAiProcess",
          getPath: "aiprocess/aiProcessesList",
          textDelete: "AI Process deleted successfully!",
          textDefaultData: 'tags'
        });
      }
      else {
        this.tableAiProcesses.setMessage('No results match your search query');
      }
    }
  },
};
</script>
