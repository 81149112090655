<template>
    <div id="apiProcess" class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card card-body mt-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <h5 class="mb-0">Ai Process Settings</h5>
                    </div>
                </div>
                <div class="card card-body mt-4">
                    <div class="row">
                        <div class="col-6">
                            <div class="mt-4">
                                <div class="table-responsive">
                                    <label class="form-label">Ai Version</label>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    MODEL</th>
                                                <th
                                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                    DESCRIPTION</th>
                                                <th
                                                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    CONTEXT WINDOW</th>
                                                <th
                                                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    TRAINING DATA
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><argon-radio id="gpt-4-1106-preview" v-model="aiSetting.api_version"
                                                        name="gpt-4-1106-preview" value="gpt-4-1106-preview"
                                                        :checked="aiSetting.api_version == 'gpt-4-1106-preview' ? true : false">gpt-4-1106-preview</argon-radio>
                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">The latest GPT-4 model with improved
                                                        instruction
                                                        following, JSON
                                                        mode,
                                                        reproducible
                                                        outputs, parallel function calling, and more. Returns a maximum
                                                        of
                                                        4,096
                                                        output tokens.
                                                        This preview model is not yet suited for production traffic.</p>
                                                </td>
                                                <td class="text-sm">128,000 tokens</td>
                                                <td class="text-sm">Up to Apr 2023</td>
                                            </tr>
                                            <tr>
                                                <td><argon-radio id="gpt-4" v-model="aiSetting.api_version" name="gpt-4"
                                                        value="gpt-4"
                                                        :checked="aiSetting.api_version == 'gpt-4' ? true : false">gpt-4</argon-radio>
                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">Snapshot of gpt-4 from June 13th 2023
                                                        with improved function calling support.</p>
                                                </td>
                                                <td class="text-sm">8,192 tokens</td>
                                                <td class="text-sm">Up to Sep 2021</td>
                                            </tr>
                                            <tr>
                                                <td><argon-radio id="gpt-4-32k" v-model="aiSetting.api_version"
                                                        name="gpt-4-32k" value="gpt-4-32k"
                                                        :checked="aiSetting.api_version == 'gpt-4-32k' ? true : false">gpt-4-32k</argon-radio>
                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">Snapshot of gpt-4-32k from June 13th
                                                        2023 with improved function calling support.</p>
                                                </td>
                                                <td class="text-sm">32,768 tokens</td>
                                                <td class="text-sm">Up to Sep 2021</td>
                                            </tr>
                                            <tr>
                                                <td> <argon-radio id="gpt-3.5-turbo-1106" v-model="aiSetting.api_version"
                                                        name="gpt-3.5-turbo-1106" value="gpt-3.5-turbo-1106"
                                                        :checked="aiSetting.api_version == 'gpt-3.5-turbo-1106' ? true : false">gpt-3.5-turbo-1106</argon-radio>
                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">The latest GPT-3.5 Turbo model with
                                                        improved instruction following, JSON mode, reproducible outputs,
                                                        parallel function calling, and more. Returns a maximum of 4,096
                                                        output tokens.</p>
                                                </td>
                                                <td class="text-sm">16,385 tokens</td>
                                                <td class="text-sm">Up to Sep 2021</td>
                                            </tr>
                                            <tr>
                                                <td><argon-radio id="gpt-3.5-turbo" v-model="aiSetting.api_version"
                                                        name="gpt-3.5-turbo" value="gpt-3.5-turbo"
                                                        :checked="aiSetting.api_version == 'gpt-3.5-turbo' ? true : false">gpt-3.5-turbo</argon-radio>
                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">Snapshot of gpt-3.5-turbo from June 13th
                                                        2023.</p>
                                                </td>
                                                <td class="text-sm">4,096 tokens</td>
                                                <td class="text-sm">Up to Sep 2021</td>
                                            </tr>
                                            <tr>
                                                <td> <argon-radio id="gpt-3.5-turbo-16k" v-model="aiSetting.api_version"
                                                        name="gpt-3.5-turbo-16k" value="gpt-3.5-turbo-16k"
                                                        :checked="aiSetting.api_version == 'gpt-3.5-turbo-16k' ? true : false">gpt-3.5-turbo-16k</argon-radio>

                                                </td>
                                                <td>
                                                    <p class="text-sm text-wrap">Snapshot of gpt-3.5-16k-turbo from June
                                                        13th 2023.</p>
                                                </td>
                                                <td class="text-sm">16,385 tokens</td>
                                                <td class="text-sm">Up to Sep 2021</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mt-4">
                                <label class="form-label">API KEY</label>
                                <argon-input v-model="aiSetting.api_key" type="text" placeholder="API KEY"
                                    :value="aiSetting.api_key" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <argon-button class="mt-4 mx-auto d-block" color="success" size="lg" variant="gradient"
                                @click="handleSave()" :disabled="isLoading">Save</argon-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonRadio from '@/components/ArgonRadio.vue';

import Choices from "choices.js";
import showSwal from "@/mixins/showSwal.js"

export default {

    name: "AiProcessesSettingsPage",

    props: [],

    components: {
        ArgonInput,
        ArgonButton,
        ArgonRadio
    },

    mixins: [showSwal],

    data() {
        return {
            isLoading: false,
            aiSetting: {
                type: "aiProcessSettings",
                id: "",
                api_key: "",
                api_version: "",
            },
        }
    },

    computed: {

    },

    async mounted() {
        await this.$store.dispatch("aiprocess/getAiProcessSettings", this.$route.params.id);
        this.aiSetting = await this.$store.getters["aiprocess/aiSetting"];

        if (this.aiSetting == null) {

            this.aiSetting = {
                type: "ai-process-settings",
                api_key: "YOUR_API_KEY_HERE",
                api_version: "gpt-4",
            }

            await this.$store.dispatch("aiprocess/addAiProcessSettings", this.aiSetting);

            await this.$store.dispatch("aiprocess/getAiProcessSettings", this.$route.params.id);
            this.aiSetting = await this.$store.getters["aiprocess/aiSetting"];
        }
    },

    async updated() {
        this.getChoices("choices-ai-version-edit");
    },

    methods: {

        getChoices(id) {
            if (document.getElementById(id)) {
                const element = document.getElementById(id);
                return new Choices(element, {
                    searchEnabled: false,
                });
            }
        },
        async handleSave() {
            try {
                this.isLoading = true;
                // this.resetApiValidation();
                delete this.aiSetting.links;

                await this.$store.dispatch("aiprocess/editAiProcessSettings", this.aiSetting);

                this.showSwal({
                    type: "success",
                    message: "Process finished successfully!",
                });

                this.isLoading = false;

            } catch (error) {
                this.isLoading = false;
                // this.setApiValidation(error.response.data.errors);
                this.showSwal({
                    type: "error",
                    message: "Oops, something went wrong!",
                });
            }
        }

    },
};
</script>
