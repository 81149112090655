import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function createAiProcess(aiProcess) {
    const payload = jsona.serialize({
        stuff: aiProcess,
        includeNames: []
    });

    const response = await axios.post(`${API_URL}/ai-process`, payload, { headers: authHeader() });
    return jsona.deserialize(response.data);
}

async function updateAiProcess(aiProcess) {
    const payload = jsona.serialize({
        stuff: aiProcess,
        includeNames: ['prompts']
    });

    const response = await axios.post(`${API_URL}/save-ai-process`, payload, { headers: authHeader() });
    return jsona.deserialize(response.data);
}

async function saveAsAiProcess(aiProcess) {
    const payload = jsona.serialize({
        stuff: aiProcess,
        includeNames: ['prompts']
    });

    const response = await axios.post(`${API_URL}/save-as-ai-process`, payload, { headers: authHeader() });
    return jsona.deserialize(response.data);
}

async function getAiProcess(itemId) {

    const response = await axios.get(`${API_URL}/ai-processes/${itemId}?include=prompts`, { headers: authHeader() });

    return jsona.deserialize(response.data);
}

async function getAiProcesses() {

    const response = await axios.get(`${API_URL}/ai-processes?filter[active]=1`, { headers: authHeader() });

    return { data: jsona.deserialize(response.data), meta: response.data.meta?.page };
}

async function getAiProcessSettings() {

    const response = await axios.get(`${API_URL}/ai-process-settings`, { headers: authHeader() });

    return jsona.deserialize(response.data);
}

async function editAiProcessSettings(item) {

    const payload = jsona.serialize({
        stuff: item,
        includeNames: []
    });

    const response = await axios.patch(`${API_URL}/ai-process-settings/${item.id}`, payload, { headers: authHeader() });
    return jsona.deserialize(response.data);
}

async function createAiProcessSettings(item) {
    const payload = jsona.serialize({
        stuff: item,
        includeNames: []
    });

    const response = await axios.post(`${API_URL}/ai-process-settings`, payload, { headers: authHeader() });
    return jsona.deserialize(response.data);
}

async function deleteAiProcess(itemId) {
    await axios.delete(`${API_URL}/ai-processes/${itemId}`, { headers: authHeader() });
}


export default {
    createAiProcess,
    getAiProcess,
    getAiProcesses,
    updateAiProcess,
    saveAsAiProcess,
    getAiProcessSettings,
    editAiProcessSettings,
    createAiProcessSettings,
    deleteAiProcess
};
