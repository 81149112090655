<template>
    <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
        <ul class="navbar-nav">
            <li class="mt-3 nav-item">
                <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
                    PAGES
                </h6>
            </li>

            <li class="nav-item">
                <sidenav-collapse ref="examplesApi" collapse-ref="apiExamples" nav-text="User Management"
                    :class="getRoute() === 'examples' ? 'active' : ''">
                    <template #icon>
                        <i class="fas fa-user text-primary text-sm opacity-10"></i>
                    </template>
                    <template #list>
                        <ul class="nav ms-4">
                            <!-- nav links -->
                            <sidenav-item :to="{ name: 'User Profile' }" mini-icon="U" text="User Profile" />
                            <div v-if="role == 'admin'">
                                <sidenav-item :to="{ name: 'Role Management' }" mini-icon="R" text="Role Management" />
                                <sidenav-item :to="{ name: 'User Management' }" mini-icon="RM" text="User Management" />
                            </div>
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>
            <li class="nav-item">
                <sidenav-collapse ref="examplesApi" collapse-ref="apiExamples" nav-text="Open AI"
                    :class="getRoute() === 'ai' ? 'active' : ''">
                    <template #icon>
                        <i class="fas fa-comment text-primary text-sm opacity-10"></i>
                    </template>
                    <template #list>
                        <ul class="nav ms-4">
                            <!-- nav links -->
                            <sidenav-item :to="{ name: 'AI Process' }" mini-icon="U" text="New Open AI Process" />
                            <sidenav-item :to="{ name: 'AI Processes' }" mini-icon="RM" text="Open AI Processes" />
                            <sidenav-item :to="{ name: 'AI Settings' }" mini-icon="RM" text="Open AI Settings" />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>
        </ul>
    </div>
</template>
<script>

import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";

export default {
    name: "SidenavList",
    components: {
        SidenavItem,
        SidenavCollapse,

    },
    computed: {
        role() {
            return this.$store.getters["profile/me"]?.roles[0].name;
        },
    },
    async mounted() {
        this.$refs.examplesApi.isExpanded = true;
    },
    methods: {
        getRoute() {
            const routeArr = this.$route.path.split("/");
            return routeArr[1];
        },
    },
};
</script>
