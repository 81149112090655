/* eslint-disable no-unused-vars */
import aiProcessService from "../services/aiprocess.service.js";

export const aiprocess = {
    namespaced: true,
    state: {
        aiProcessesList: null,
        oneAiProcess: null,
        aiSetting: null,
    },
    getters: {
        aiProcessesList: state => state.aiProcessesList,
        oneAiProcess: state => state.oneAiProcess,
        aiSetting: state => state.aiSetting,
    },
    actions: {
        async aiProcessesList({ commit }) {
            const data = await aiProcessService.getAiProcesses();
            commit('AIPROCESSES_LIST', data);
        },
        async getAiProcess({ commit }, itemId) {
            const data = await aiProcessService.getAiProcess(itemId);
            commit('GET_AI_PROCESS', data);
        },
        async addAiProcess({ commit }, aiProcess) {
            const data = await aiProcessService.createAiProcess(aiProcess);
            commit('GET_AI_PROCESS', data);
        },
        async updateAiProcess({ commit }, data) {
            await aiProcessService.updateAiProcess(data);
            commit('GET_AI_PROCESS', data);
        },
        async saveAsAiProcess({ commit }, data) {
            await aiProcessService.saveAsAiProcess(data);
            commit('GET_AI_PROCESS', data);
        },
        async deleteAiProcess({ commit }, itemId) {
            await aiProcessService.deleteAiProcess(itemId);
        },
        async getAiProcessSettings({ commit }) {
            const result = await aiProcessService.getAiProcessSettings();
            const data = result[0];
            commit('GET_AI_PROCESS_SETTING', data);
        },
        async editAiProcessSettings({ commit }, item) {
            await aiProcessService.editAiProcessSettings(item);
        },
        async addAiProcessSettings({ commit }, item) {
            const data = await aiProcessService.createAiProcessSettings(item);
            commit('GET_AI_PROCESS_SETTING', data);
        },

    },
    mutations: {
        AIPROCESSES_LIST(state, data) {
            state.aiProcessesList = data;
        },
        GET_AI_PROCESS(state, data) {
            state.oneAiProcess = data;
        },
        GET_AI_PROCESS_SETTING(state, data) {
            state.aiSetting = data;
        },
    }
}
