import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/dashboards/Dashboard.vue";

import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";

import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import UserProfile from '../views/examplesApi/UserProfile/EditUserProfile.vue';
import ListUserPage from '../views/examplesApi/UserManagement/ListUserPage.vue';
import AddUserPage from '../views/examplesApi/UserManagement/AddUserPage.vue';
import EditUserPage from '../views/examplesApi/UserManagement/EditUserPage.vue';
import AiProcessPage from '../views/ai/AiProcessPage.vue';
import EditAiProcessPage from '../views/ai/EditAiProcessPage.vue';

import ListAiProcessesPage from '../views/ai/ListAiProcessesPage.vue';
import AiProcessesSettingsPage from '../views/ai/AiProcessesSettingsPage.vue';

import ListRolePage from '../views/examplesApi/RoleManagement/ListRolePage.vue';
import AddRolePage from '../views/examplesApi/RoleManagement/AddRolePage.vue';
import EditRolePage from '../views/examplesApi/RoleManagement/EditRolePage.vue';

import auth from '../middleware/auth';
import guest from '../middleware/guest';
import admin from "../middleware/admin";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/examples/user-profile",
    name: "User Profile",
    component: UserProfile,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/ai/ai-process",
    name: "AI Process",
    component: AiProcessPage,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/ai/ai-processes/edit-process/:id",
    name: "Show AI Process",
    component: EditAiProcessPage,
    meta: {
      middleware: [auth, admin]
    }
  },
  {
    path: "/ai/ai-processes",
    name: "AI Processes",
    component: ListAiProcessesPage,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/ai/ai-settings",
    name: "AI Settings",
    component: AiProcessesSettingsPage,
    meta: {
      middleware: auth
    }
  },
  {
    path: "/examples/role-management/list-roles",
    name: "Role Management",
    component: ListRolePage,
    meta: {
      middleware: [auth, admin]
    }
  },
  {
    path: "/examples/role-management/add-role",
    name: "Add Role",
    component: AddRolePage,
    meta: {
      middleware: [auth, admin]
    }
  },
  {
    path: "/examples/role-management/edit-role/:id",
    name: "Edit Role",
    component: EditRolePage,
    meta: {
      middleware: [auth, admin]
    }
  },
  {
    path: "/examples/user-management/list-users",
    name: "User Management",
    component: ListUserPage,
    meta: {
      middleware: [auth, admin]
    }
  },
  {
    path: "/examples/user-management/add-user",
    name: "User Add",
    component: AddUserPage,
    meta: {
      middleware: [auth, admin]
    }
  },
  {
    path: "/examples/user-management/edit-user/:id",
    name: "Edit User",
    component: EditUserPage,
    meta: {
      middleware: [auth, admin]
    }
  },

  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: guest
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      middleware: guest
    }
  },
  {
    path: "/password/forgot",
    name: "Forgot Password",
    component: ForgotPassword,
    meta: {
      middleware: guest
    }
  },
  {
    path: "/password/reset",
    name: "Reset Password",
    component: ResetPassword,
    meta: {
      middleware: guest
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});

// Creates a nextMiddleware() function which not only
// runs the default next() callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default next() callback is returned.
  if (!subsequentMiddleware)
    return context.next;

  return (...parameters) => {
    // Run the default Vue Router next() callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // nextMiddleware() callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
    const context = { from, next, to, router };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
