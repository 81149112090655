<template>
  <footer class="py-5 footer">

  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
